<template>
    <div class="listaRapportini" style="background-color: white;">
        <v-data-table :headers="headers"
                      :items="rapporti"
                      :items-per-page="5"
                      class="trFixedWidth"
                      :footer-props="{'items-per-page-text': 'Elementi Per Pagina', 'items-per-page-options': [10,15,-1], 'items-per-page-all-text': 'Tutti'}"
                      :header-props="{'sort-by-text': 'Ordina Per'}"
                      sort-by="numero"
                      sort-desc
                      no-data-text="Nessun rapporto"
                      @click:row="rowClick"
                      item-class="trimTextTooLong"
        >
            <template v-slot:item.segnala="{item}">
                <v-btn small icon @click="segnalaRapporto(item, $event)">
                    <v-icon small color="error">fas fa-exclamation</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-container grid-list-sm fluid v-if="showFatto" class="mt-3">
            <v-row>
                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                    <v-btn color="primary" width="100%" @click="emitAndClose">Fatto</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="modalSchedaRapporto" max-width="700px">
            <SchedaRapporto :id="id_rapporto_clicked" :id_attivita="id_attivita" v-if="id_rapporto_clicked && modalSchedaRapporto" @rapportinoDone="closeScheda" :hasPermission="hasPermission" :libero="libero"/>
        </v-dialog>
        <v-dialog v-model="modalNewRapporto" persistent max-width="700px">
            <ModalNewSegnalazione :rapporto="rapporto_selected" v-if="rapporto_selected && modalNewRapporto" @rapportinoDone="closeModalNewRapporto" :id_attivita="id_attivita"/>
        </v-dialog>
    </div>
</template>

<script>
import apiRapporti from '@/js/pages/rapporti';
const SchedaRapporto = () => import('@/components/NewRapporto');
const ModalNewSegnalazione = () => import('@/components/ModalNewSegnalazione');

export default {
    name: 'listaRapportini',
    props: [
        'id_attivita',
        'id_reparto',
        'showFatto',
        'isMaster',
        'libero'
    ],
    components: {
        SchedaRapporto,
        ModalNewSegnalazione
    },
    watch: {
        viewEvacuatore(){
            if(this.viewEvacuatore)
                this.showEvacuatore(this.viewEvacuatore);
        },
        async id_reparto() {
            await this.initElenco();
        }
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            schede: [],
            headers: [
                /*{
                    text: 'Numero',
                    name: 'numero',
                    align: 'start',
                    width: '15%',
                    value: 'numero',
                },*/ {
                    text: 'Reparto',
                    name: 'reparto',
                    align: 'start',
                    width: '15%',
                    value: 'rNameReparto'
                }, {
                    text: 'Matricola',
                    width: '15%',
                    value: 'matricola_evacuatore',
                }, {
                    text: 'Note',
                    value: 'note'
                }
            ],
            rapporti: [],
            id_rapporto_clicked: undefined,
            modalSchedaRapporto: false,
            rapporto_selected: undefined,
            modalNewRapporto: false
        }
    },
    computed: {
        hasPermission() {
            if(
                this.isMaster === undefined ||
                this.isMaster === null
            ) return true;
            else return this.isMaster;
        }
    },
    async created() {
        if(this.hasPermission)
            this.headers.push({
                text: 'Segnala',
                width: '15%',
                value: 'segnala',
                sortable: false
            });
        if(this.multipleChosing) this.headers.splice(0, 0, { text: 'Seleziona', value: 'multipleSelecting' });
        if(
            this.list_selected &&
            this.list_selected.length
        ) this.array_evacuatori_clicked = [...this.list_selected];
        await this.initElenco();
    },
    methods: {
        async initElenco() {
            let arr = [];
            if(this.id_attivita) arr = await apiRapporti.getAllFromAttivita(this.token, this.id_attivita, this.id_reparto);
            if(arr) this.rapporti = arr;
        },
        rowClick(rapporto){
            if(rapporto._id) this.id_rapporto_clicked = rapporto._id;
            this.modalSchedaRapporto = true;
        },
        emitAndClose() {
            this.$emit('rapportiDone');
        },
        async closeScheda(info) {
            if(info === 'reloadRapporti') await this.initElenco();
            this.id_evacuatore_clicked = undefined;
            this.modalSchedaRapporto = false;
        },
        async segnalaRapporto(row, event) {
            if(row) {
                event.preventDefault();
                event.stopPropagation();
                this.rapporto_selected = row;
                this.modalNewRapporto = true;
            }
        },
        closeModalNewRapporto() {
            this.rapporto_selected = undefined;
            this.modalNewRapporto = false;
        }
    },
}
</script>